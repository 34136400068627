import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import "./ListaCuentas.css"
import { GrFormSearch } from 'react-icons/gr';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import { FaTrashAlt } from "react-icons/fa";
import AgregarUsuarios from "../../componentes/Modals/AgregarUsuarios/AgregarUsuarios";
import { eliminarCliente, getCuentas, getUser } from "../../servicios/api";
import ModalDetalles from "../../componentes/Modals/ModalDetalles/ModalDetalles";
import ModalMessage from "../../componentes/Modals/ModalMessages/ModalMessages";


export default class ListaCuentas extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            txtusuario: "",
            txt_buscador: "",
            array: [
                {
                    "usuario": "Arias",
                    "estatus": false,
                    "cuentas": 10,
                    "activo": true,
                },
            ],
            array_filtro: [],
            respaldo_data: [],
            loanding: false,
            fechaselect: null,
            fechajson: '',
            ver_multipagos: false,
            ver_fecha: false,
            veruser: false,
            fecha: '',
            sortBy: "email",
            sortOrder: 'asc',
            selectedRowIndex: null,
            selectedRow: null,
            verMJS: false,
            dataUser: [],
            modalTitulo: "",
            modalMessage: "",
            vermodaldetalles: false,
            banderaMensaje: ""
        }

    }

    async componentDidMount() {

        this.setState({ loanding: true });
        var resp = await getCuentas()


        if (resp.code == 0) {
            var temp = resp.data;
            for (let i = 0; i < temp.length; i++) {
                temp[i].seleccionar = false;
            }


            this.setState({ array: resp.data })
        }

        setTimeout(async () => {

            this.setState({ loanding: false });

        }, 300);



    }

    handleAgeChange = (index) => {
        this.setState((prevState) => {
            const newData = [...prevState.data];
            // Incrementa la edad del elemento en la posición del índice
            newData[index].edad++;
            return { data: newData };
        });
    };

    save_input_locacion = async (e) => {

        var filtropuesto = this.state.array;
        for (let i = 0; i < filtropuesto.length; i++) {
            filtropuesto[i].seleccionar = false;
        }
        this.setState({ array: filtropuesto })


        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });

        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.email.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()))

            if (filtrofinal.length <= 0) {
                this.setState({ array_filtro: [] })
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }

    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    agregarUser = async (index) => {
        //console.log("Click " + index)
        this.setState({ veruser: true })
    }

    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }



    seleccionarTabla2 = (index, bandera) => {
        this.setState((prevState) => {
            const newData = prevState.array.map((item, i) => {
                if (i === index) {
                    return { ...item, seleccionado: true };
                } else {
                    return { ...item, seleccionado: false };
                }
            });
            return { array: newData, selectedRow: index };
        });
    };

    seleccionarTabla = (arrayd, bandera) => {
        //console.log(bandera + "  " + JSON.stringify(arrayd))
        if (bandera === "lista") {
            const { array } = this.state;
            const newData = array.map((item) => {
                if (item.idcliente == arrayd.idcliente) {
                    return { ...item, seleccionar: !item.seleccionar };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array: newData });
        }
        else if (bandera === "listaboton") {
            const { array } = this.state;
            const newData = array.map((item) => {
                if (item.idcliente == arrayd.idcliente) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array: newData });
        }
        else if (bandera === "filtroboton") { // filtro boton 
            const { array_filtro } = this.state;
            const newData = array_filtro.map((item) => {
                if (item.idcliente == arrayd.idcliente) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_filtro: newData });
        }
        else { // filtro
            const { array_filtro } = this.state;
            const newData = array_filtro.map((item) => {
                if (item.idcliente == arrayd.idcliente) {
                    return { ...item, seleccionar: !item.seleccionar };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_filtro: newData });

        }
    }

    modalCategorias = (bandera, item, tipo) => {


        //console.log("ban " + bandera)
        if (bandera == 0) {
            this.setState({ vermodaldetalles: !this.state.vermodaldetalles })
        }
        else {
            this.seleccionarTabla(item, tipo)
            this.setState({ dataUser: item, vermodaldetalles: true })
        }

    }




    eliminar = async (dato, item) => {
        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {
            this.setState({ verMJS: !this.state.verMJS })
            this.setState({ loanding: true });
            var resp = await eliminarCliente(this.state.respaldo_data.idcliente)

            if (resp.code == 0) {
                this.componentDidMount()
            }

        }

        else {

            var correo = "";
            if (item.email != "") {
                correo = item.email[0] + '...' + item.email.substring(item.email.indexOf('@') - 1)
                //console.log(" --------  ")
            }
            else {
                //console.log(" +++++++  ")
                correo = " - Invitado"
            }
            this.setState({
                banderaMensaje: "eliminarCliente",
                respaldo_data: item,
                modalTitulo: "Eliminar Cliente",
                modalMessage: "*" + item.nombre + "* " + correo,
                verMJS: !this.state.verMJS
            })

        }

    }

    editarUser = (item) => {


        this.setState({
            banderaModal: "editar",
            datosUser: item,
            veruser: true
        })


    }

    closemodal = (dato) => {
        this.setState({ veruser: !this.state.veruser })
        if (dato == 1) {

            //  this.componentDidMount();

        }
    }

    render() {

        const { array, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return (
            <>


                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.veruser == true) ? (
                    <AgregarUsuarios show={this.state.veruser} infoModal={[]}
                        cerrarmodal={this.closemodal} />
                ) : null}

                {(this.state.vermodaldetalles == true) ? (
                    <ModalDetalles show={this.state.vermodaldetalles} datosUser={this.state.dataUser}
                        cerrarmodal={this.modalCategorias} />
                ) : null}

                {(this.state.verMJS == true) ? (
                    <ModalMessage show={this.state.verMJS} titulo={this.state.modalTitulo} bandera={this.banderaMensaje}
                        mensaje={this.state.modalMessage} cerrarmodal={this.eliminar} />
                ) : null}

                <div className="contenedorhome  ">

                    <div className="row scroll">
                        <div className="col">


                            <br />
                            <label className="lbllistat letra">Clientes</label>



                            <div className="row align-items-end">
                                <div className="col-auto ">
                                    <div className="input-group ">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                        </div>
                                        <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                            className="form-control" aria-label="Username" placeholder="Buscar" aria-describedby="basic-addon1"></input>
                                    </div>
                                </div>
                                <div className="col"></div>
                                <div className="col-auto">
                                </div>
                            </div>

                            <div className="shr "></div>
                            <br />

                            <div className="divhorizontal ">

                                <div className="row srows" style={{ paddingRight: "20px" }}>

                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('email')}>
                                        <label className="txttitlestable cursor" >Correo</label>
                                    </div>
                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('nombre')}>
                                        <label className="txttitlestable cursor">Usuario</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('cuentas')}>
                                        <label className="txttitlestable cursor">Total</label>
                                    </div>
                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('pagadas')}>
                                        <label className="txttitlestable cursor">Pagadas</label>
                                    </div>

                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('tipocliente')}>
                                        <label className="txttitlestable cursor">Tipo</label>
                                    </div>
                                    <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('estatus')}>
                                        <label className="txttitlestable cursor">Estatus</label>
                                    </div>
                                    {/*<div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('registro')}>
                                        <label className="txttitlestable">Fecha de registro</label>
                                    </div>
                                    onClick={this.modalCategorias.bind(this, 1, item)} */}


                                </div>
                                <div className='divscrollhome'>

                                    {this.state.txt_buscador.length == 0 ?
                                        <>
                                            {sortedData.map((item, index) => (
                                                <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                    onClick={() => this.seleccionarTabla(item, "lista")}>



                                                    <div className="col-2 spaciostabla textocentro sinpadding " onClick={() => this.modalCategorias(1, item, "lista")}>
                                                        <label className="subtexttable">
                                                            {item.email && item.email.length > 0 ?
                                                                item.email[0] + '...' + item.email.substring(item.email.indexOf('@') - 1) :
                                                                '-----'}
                                                        </label>
                                                    </div>
                                                    <div className="col-2 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "lista")}>
                                                        <label className="subtexttable">{item.nombre}</label>
                                                    </div>
                                                    <div className="col-1 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "lista")}>
                                                        <label className="subtexttable"><b>{item.cuentas}</b></label>
                                                    </div>
                                                    <div className="col-2 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "lista")}>
                                                        <label className="subtexttable"><b>{item.pagadas}</b></label>

                                                    </div>

                                                    <div className="col-1 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "lista")}>
                                                        <label className="subtexttable">{item.tipocliente}</label>
                                                    </div>
                                                    <div className="col-1 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "lista")}>
                                                        <label className="subtexttable">{item.estatus}</label>

                                                    </div>
                                                    <div className="col spaciostabla textocentro sinpadding">

                                                        <FaTrashAlt className="icodelate" onClick={(e) => {
                                                            e.stopPropagation()
                                                            this.eliminar(3, item);
                                                        }} />

                                                        {/* <Button className="buttonCats" style={{ width: "fit-content", fontSize: "12px", marginRight: "3px", borderRadius: "5px", paddingLeft: "2px", paddingRight: "6px" }}
                                                            onClick={this.editarUser.bind(this, item)}><CiEdit style={{ marginRight: "5px" }} />Editar</Button>*/}

                                                        <Button className="buttonCats" style={{ fontSize: "12px", paddingLeft: "4px", paddingRight: "4px" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Detiene la propagación para evitar abrir el modal
                                                                this.modalCategorias(1, item, "lista");
                                                            }}>Detalles</Button>
                                                    </div>

                                                </div>
                                            ))}

                                        </> :
                                        <>
                                            {this.state.array_filtro.map((item, index) => (

                                                <>
                                                    <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                        onClick={() => this.seleccionarTabla(item, "filtro")}>

                                                        <div className="col-2 spaciostabla textocentro sinpadding " onClick={() => this.modalCategorias(1, item, "filtro")}>
                                                            <label className="subtexttable">
                                                                {item.email && item.email.length > 0 ?
                                                                    item.email[0] + '...' + item.email.substring(item.email.indexOf('@') - 1) :
                                                                    '-----'}
                                                            </label>

                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "filtro")}>
                                                            <label className="subtexttable">{item.nombre}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "filtro")}>
                                                            <label className="subtexttable"><b>{item.cuentas}</b></label>
                                                        </div>
                                                        <div className="col-2 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "filtro")}>
                                                            <label className="subtexttable"><b>{item.pagadas}</b></label>

                                                        </div>

                                                        <div className="col-1 spaciostabla textocentro sinpadding" onClick={() => this.modalCategorias(1, item, "filtro")}>
                                                            <label className="subtexttable">{item.tipocliente}</label>
                                                        </div>
                                                        <div className="col-1 spaciostabla textocentro sinpadding">
                                                            <label className="subtexttable">{item.estatus}</label>

                                                        </div>
                                                        <div className="col spaciostabla textocentro sinpadding" >
                                                            <FaTrashAlt className="icodelate" onClick={this.eliminar.bind(this, 3, item)} />

                                                            {/*<Button className="buttonCats" style={{ width: "fit-content", fontSize: "12px", marginRight: "3px", borderRadius: "5px", paddingLeft: "2px", paddingRight: "6px" }}
                                                                onClick={this.editarUser.bind(this, item)}><CiEdit style={{ marginRight: "5px" }} />Editar</Button>*/}

                                                            <Button className="buttonCats" style={{ fontSize: "12px" }}
                                                                onClick={this.modalCategorias.bind(this, 1, item, "filtroboton")} >Detalles</Button>
                                                        </div>


                                                    </div>

                                                </>
                                            ))}
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

