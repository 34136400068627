import React from "react";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import "./ModalCategorias.css"
import { ChromePicker } from 'react-color';
import { saveCategoria } from "../../../servicios/api";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";


export default class ModalCategorias extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            show: this.props.show,
            titulo: this.props.titulo,
            mensaje: this.props.mensaje,
            botones: this.props.options,
            selecCategoria: this.props.arrayInfo,
            bandera: this.props.bandera, // 1 categoria, 2 sub categoria 
            arrayCategorias: this.props.arrayCategorias,
            displayColorPicker: false,
            seleccionarCategoria: "",
            tipo: "",
            selectedColor: "#ffffff",
            txticono: "",
            txtname: "",
            txtdes: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ seleccionarCategoria: event.target.value });
    }

    componentDidMount() {

        //console.log(JSON.stringify(this.state.selecCategoria))
      //console.log(this.state.bandera)
      //console.log(JSON.stringify(this.state.selecCategoria))
      //console.log("----------------------");

        if (this.state.bandera == "NuevaCat") {
            this.setState({ seleccionarCategoria: this.state.selecCategoria.idcategoria })
            this.setState({ tipo: this.state.selecCategoria.type })

        }
        else if (this.state.bandera == "NuevaSub") {
            this.setState({ seleccionarCategoria: this.state.selecCategoria.idcategoria })
            this.setState({ tipo: this.state.selecCategoria.type })

        }
        else {
            this.setState({
                txtname: this.state.selecCategoria.name,
                txtdes: this.state.selecCategoria.description,
                txticono: this.state.selecCategoria.icon,
                selectedColor: "#" + this.state.selecCategoria.color,
                seleccionarCategoria: this.state.selecCategoria.parent,
                tipo: this.state.selecCategoria.type
            })
        }


    }

    guardar_datos = async () => {


        const timestamp = this.getCurrentTimestamp();
        const newStr = this.state.selectedColor.substring(1)
        var json = {}
        if (this.state.bandera == "NuevaCat") {

            json = {
                "id": "0",
                "idcategoria": timestamp,
                "name": this.state.txtname,
                "description": this.state.txtdes,
                "icon": this.state.txticono,
                "type": this.state.tipo,
                "color": newStr,
                "parent": ""
            }

          //console.log("Nueva Cat \n" + JSON.stringify(json))
            //var resp = await saveCategoria(json)

        }

        else if (this.state.bandera == "editarCat") {

            json = {
                "id": this.state.selecCategoria.id,
                "idcategoria": this.state.selecCategoria.idcategoria,
                "name": this.state.txtname,
                "description": this.state.txtdes,
                "icon": this.state.txticono,
                "type": this.state.tipo,
                "color": newStr,
                "parent": ""
            }

          //console.log("Update Cat \n" + JSON.stringify(json))
            //var resp = await saveCategoria(json)
        }

        else if (this.state.bandera == "NuevaSub") {

            json = {
                "id": "0",
                "idcategoria": timestamp,
                "name": this.state.txtname,
                "description": this.state.txtdes,
                "icon": this.state.txticono,
                "type": this.state.tipo,
                "color": newStr,
                "parent": this.state.seleccionarCategoria
            }
          //console.log("Nueva Sub \n" + JSON.stringify(json))
        }

        else if (this.state.bandera == "editarSub") {

            json = {
                "id": this.state.selecCategoria.id,
                "idcategoria": this.state.selecCategoria.idcategoria,
                "name": this.state.txtname,
                "description": this.state.txtdes,
                "icon": this.state.txticono,
                "type": this.state.tipo,
                "color": newStr,
                "parent": this.state.seleccionarCategoria
            }
          //console.log("Update Sub \n" + JSON.stringify(json))

        }


        this.setState({ loanding: true })

        var respp = await saveCategoria(json)

        this.setState({ loanding: false })
        this.closemodal(2)




    }

    getCurrentTimestamp = () => {
        return Math.floor(new Date().getTime() / 1000); // dividido por 1000 para obtener los segundos en lugar de milisegundos

    }

    closemodal = (response) => {
        this.setState({ show: false });
        this.props.cerrarmodal(response, this.state.bandera);
    }

    handlePasswordChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    toggleColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }

    handleColorChange = (color) => {
        this.setState({ selectedColor: color.hex });
    }

    selecTipo = (tipo) => {
        this.setState({ tipo: tipo });
    }

    
    render() {
        return (
            <>

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                <Modal isOpen={this.state.show}
                    size="sm"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, -1)}
                    className="modal-messages">
                    <ModalBody className="bodybackgroundblack">
                        <div className="modalmessage-header" style={{ textAlign: "center", color: "black" }}>
                            <p><b> {this.state.titulo} </b></p>
                        </div>
                        <br />

                        <div style={{ marginBottom: "15px", display: this.state.bandera == "NuevaSub" || this.state.bandera == "editarSub" ? "block" : "none" }}>
                            <div className="modalmessage-header" style={{ textAlign: "Start", color: "black", marginBottom: "3px" }}>
                                <p><b>Pertenece a:</b></p>
                            </div>
                            <select value={this.state.seleccionarCategoria} onChange={this.handleChange}
                                style={{ width: "100%", padding: "5px", border: "solid 2px #0066c0", borderRadius: "10px", color: "black" }} >
                                <option value="">Seleccionar...</option>
                                {this.state.arrayCategorias.map((categoria, index) => (
                                    categoria.parent === "" ? (
                                        <option key={index} value={categoria.idcategoria} style={{ backgroundColor: this.state.seleccionarCategoria === categoria.idcategoria ? 'lightblue' : 'white' }}>
                                            {categoria.name}
                                        </option>
                                    )
                                        : null


                                ))}
                            </select>


                            <br />

                        </div>

                        <div className="modalmessage-header" style={{ textAlign: "Start", color: "black", marginBottom: "3px" }}>
                            <p><b>Nombre:</b></p>
                        </div>

                        <Input className="inputprofile" placeholder="Nombre" type="text" style={{ paddingLeft: "5px" }}
                            onChange={this.handlePasswordChange("txtname")} value={this.state.txtname} />

                        <br />
                        <div style={{ marginBottom: "15px", display: this.state.bandera == "NuevaSub" || this.state.bandera == "editarSub" ? "block" : "none" }}>
                            <div className="modalmessage-header" style={{ textAlign: "Start", color: "black", marginBottom: "3px" }}>
                                <p><b>Descripción:</b></p>
                            </div>

                            <textarea rows="4" onChange={this.handlePasswordChange("txtdes")} placeholder="Descripción"
                                value={this.state.txtdes} style={{ width: "100%", padding: "5px", border: "solid 2px #0066c0", borderRadius: "10px", color: "black" }} />



                            <br />

                            <br />
                        </div>
                        <div style={{ display: this.state.displayColorPicker == false ? "block" : "none" }}>
                            <div className="row" >

                                <div className="col-auto">
                                    <button onClick={this.toggleColorPicker}
                                        style={{
                                            border: "solid 2px #51a3ed", marginBottom: "10px",
                                            borderRadius: "8px", backgroundColor: "#e3e3e3"
                                        }}><b>Color icono </b></button>
                                </div>
                                <div className="col" >
                                    <div style={{ backgroundColor: this.state.selectedColor, marginLeft: "auto", width: "90%", height: "32px", borderRadius: "4px" }}></div>
                                </div>
                            </div>
                        </div>



                        {this.state.displayColorPicker && (
                            <>
                                <ChromePicker color={this.state.selectedColor} onChangeComplete={this.handleColorChange} />
                                <br />

                                <div className="row">
                                    <div className="col" >
                                        <div style={{ backgroundColor: this.state.selectedColor, width: "90%", height: "34px", borderRadius: "4px" }}></div>
                                    </div>
                                    <div className="col-auto">
                                        <button style={{ border: "solid 2px #51a3ed", marginBottom: "8px", borderRadius: "8px", backgroundColor: "#e3e3e3" }} onClick={this.toggleColorPicker}>Aceptar color</button>
                                    </div>
                                </div>
                                <br />
                            </>
                        )}
                        <div className="modalmessage-header" style={{ textAlign: "Start", color: "black", marginBottom: "3px" }}>
                            <p><b>Nombre icono:</b></p>
                        </div>
                        <Input className="inputprofile" placeholder="Icono" type="text" style={{ paddingLeft: "5px" }}
                            onChange={this.handlePasswordChange("txticono")} value={this.state.txticono} />



                        <div style={{ display: this.state.bandera == "NuevaCat" || this.state.bandera == "editarCat" ? "block" : "none", marginBottom: "8px" }}>
                            <br />

                            <div className="modalmessage-header" style={{ textAlign: "Start", color: "black", marginBottom: "10px" }}>
                                <p><b>Selecciona Tipo</b></p>
                            </div>

                            <div className="row align-items-center" >
                                <div className="col"></div>
                                <div className="col-auto">
                                    <Button className={this.state.tipo == "expense" ? "buttonCats" : "buttonCatsBlanco"}
                                        onClick={this.selecTipo.bind(this, "expense")}>Egresos</Button>
                                </div>
                                <div className="col-auto">
                                    <Button className={this.state.tipo == "income" ? "buttonCats" : "buttonCatsBlanco"}
                                        onClick={this.selecTipo.bind(this, "income")}>Ingresos</Button>
                                </div>
                                <div className="col"></div>
                            </div>

                        </div>


                        <br />
                        <div className="modalmessage-footer">
                            <button className="buttonshadow message-alert-cancel " onClick={() => this.closemodal(-1)}>Cancelar</button>


                            <button className="buttonbase message-alert-ok" onClick={() => this.guardar_datos(1)}>Aceptar</button>
                        </div>
                    </ModalBody>
                </Modal>
            </>

        )
    }
}

