import React from "react";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import "./ModalDetalles.css";
import { ChromePicker } from 'react-color';
import { datosDetalles, restablecePass, saveCategoria } from "../../../servicios/api";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { GiCheckMark } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import ModalMessage from "../ModalMessages/ModalMessages";
import { ToastContainer, toast } from 'react-toastify';
import { GrFormSearch } from "react-icons/gr";
import ModalSubDetalles from "./ModalSubDetalles";

export default class ModalDetalles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pagadas: 0,
            nopagadas: 0,
            datosUser: props.datosUser,
            array: [],
            loanding: false,
            modalTitulo: "",
            modalMessage: "",
            txt_buscador: "",
            verMJS: false,
            sortBy: "email",
            sortOrder: 'asc',
            array_filtro: [],
            array_original: [],
            arraySubCuentas: [],
            verMSD: false
        };
    }

    async componentDidMount() {
        if (this.props.show) {
            // console.log("Modal abierto, cargando datos...");
            // console.log(JSON.stringify(this.props.datosUser));

            try {
                this.setState({ loanding: true });
                //const resp = await datosDetalles(this.state.datosUser.idcliente);
                var resp = await datosDetalles(this.state.datosUser.idcliente)
                //console.log(JSON.stringify(resp))
                if (resp == 0) {

                }
                else {



                    var arrayt = resp.data;
                    let pagadas = 0, nopagadas = 0;
                    for (let i = 0; i < arrayt.length; i++) {
                        arrayt[i].seleccionar = false;
                        arrayt[i].total = 0;

                        if (arrayt[i].pagado === "0") {
                            pagadas++;
                        }
                        else {
                            nopagadas++;
                        }
                    }


                    var contarTotal = arrayt.reduce((acc, item) => {
                        acc[item.idpaquete] = (acc[item.idpaquete] || 0) + 1;
                        return acc;
                    }, {});

                    const datosFiltradosConTotal = arrayt.reduce((acc, current) => {
                        var existe = acc.find(item => item.idpaquete === current.idpaquete);
                        if (!existe) {
                            acc.push({
                                ...current,
                                total: contarTotal[current.idpaquete]
                            });
                        }
                        return acc;
                    }, []);



                    this.setState({ array: datosFiltradosConTotal, array_original: arrayt, pagadas: pagadas, nopagadas: nopagadas });
                }
                this.setState({ loanding: false })
            } catch (error) {
                //console.error("Error al cargar datos:", error);
                this.setState({ loanding: false });
            }
        }

    }


    closemodal = (response) => {
        this.setState({ show: false });
        this.props.cerrarmodal(response, this.state.bandera);
    }

    formatearFecha = (fecha) => {
        const date = new Date(fecha);
        const dia = date.getDate();
        const mes = date.toLocaleString('es-ES', { month: 'short' });
        const year = date.getFullYear();
        return `${dia}/${mes}/${year}`;
    }

    seleccionarTabla = (idcuentas, idpaquete) => {
        const { array } = this.state;
        const newData = [];
        var estrella = 1;
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (item.idcuentas === idcuentas) {
                newData.push({ ...item, seleccionar: !item.seleccionar });
                estrella = 0;
            } else {
                newData.push({ ...item, seleccionar: false });
            }
        }
        this.setState({ array: newData });

        if (estrella == 0) {
            this.OpenModalSubDetalles(idpaquete)
        }

    }



    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }


    restablecer = async () => {
        this.setState({ loanding: true })
        var resp = await restablecePass(this.state.datosUser.email);
        //this.closemodal(0)


        this.setState({ loanding: false })

    }
    OpenModalSubDetalles = async (idpaquete) => {
        if (idpaquete == 0) {
            this.setState({ verMSD: !this.state.verMSD })
        }
        else {

            const resultado = this.filtrarPorIdPaquete(idpaquete);
            if (resultado.length > 1) {
                this.setState({
                    modalTitulo: "Datos",
                    arraySubCuentas: JSON.stringify(resultado),
                    verMSD: !this.state.verMSD
                })
            }
        }

    }

    filtrarPorIdPaquete = (idPaquete) => {
        return this.state.array_original.filter(item => item.idpaquete === idPaquete);
    }

    modalAviso = async (dato) => {
        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {
            this.setState({ verMJS: !this.state.verMJS })
            await this.restablecer()
            toast.success("Se envió un Correo para restablecer la contraseña", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
        }
        else {

            var correo = "";
            if (this.state.datosUser.email != "") {
                correo = this.state.datosUser.email[0] + '...' + this.state.datosUser.email.substring(this.state.datosUser.email.indexOf('@') - 1)
                //console.log(" --------  ")
            }
            else {
                //console.log(" +++++++  ")
                correo = " - Invitado"
            }

            this.setState({
                modalTitulo: "Recuperar contraseña",
                modalMessage: "*" + correo + "*",

                verMJS: !this.state.verMJS
            })
        }

    }

    save_input_locacion = async (e) => {

        var filtropuesto = this.state.array;
        for (let i = 0; i < filtropuesto.length; i++) {
            filtropuesto[i].seleccionar = false;
        }
        this.setState({ array: filtropuesto })


        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });

        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.titulo.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.textobusqueda.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()))

            if (filtrofinal.length <= 0) {
                this.setState({ array_filtro: [] })
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }

    }


    render() {

        const { array, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });


        if (!this.props.show) return null;
        return (
            <>


                <ToastContainer />


                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.verMJS == true) ? (
                    <ModalMessage show={this.state.verMJS} titulo={this.state.modalTitulo} bandera={"cambiarContrasena"}
                        mensaje={this.state.modalMessage} cerrarmodal={this.modalAviso} />
                ) : null}


                {(this.state.verMSD == true) ? (
                    <ModalSubDetalles show={this.state.verMSD} titulo={this.state.modalTitulo} bandera={"test"}
                        cerrarmodal={this.OpenModalSubDetalles} array={this.state.arraySubCuentas} />
                ) : null}



                <Modal isOpen={this.props.show}
                    size="xl"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, 0)}
                    className="modal-messages">
                    <ModalBody className="bodybackgroundblack">
                        <div className="modalmessage-header" style={{ textAlign: "center", color: "black" }}>
                            <p><b style={{ fontSize: "25px" }}> Detalles del cliente </b></p>
                        </div>
                        <br />

                        <div className="row">

                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Nombre: {this.state.datosUser.nombre}</b><br />
                                    <b style={{ color: "black" }}>Correo: {this.state.datosUser.email && this.state.datosUser.email.length > 0 ?
                                        this.state.datosUser.email[0] + '...' + this.state.datosUser.email.substring(this.state.datosUser.email.indexOf('@') - 1) :
                                        '-----'} </b>
                                </p>
                            </div>

                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Tipo: {this.state.datosUser.tipocliente}</b></p>
                            </div>

                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Fecha registro: {this.state.datosUser.registro}</b><br />
                                    <b style={{ color: "black" }}>Último login: {this.state.datosUser.registro}</b></p>
                            </div>

                        </div>

                        <br />
                        <br />

                        <div className="row align-items-end">
                            <div className="col-auto ">
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                    </div>
                                    <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                        className="form-control" aria-label="Username" placeholder="Buscar" aria-describedby="basic-addon1"></input>
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col-2">
                                <p><b>Total cuentas: {this.state.array.length} </b></p>
                            </div>

                            <div className="col-2">
                                <p><b>Pagadas: {this.state.datosUser.pagadas} </b></p>
                            </div>



                        </div>

                        <div style={{ height: "8px" }}></div>

                        <div className="row" style={{ backgroundColor: "#51a3ed", color: "black", paddingRight: "15px" }}>


                            <div className="col-2  sinpadding" onClick={() => this.ordenarLista('fechainicio')}>
                                <label className="txttitlestable cursor" style={{ paddingLeft: "5px" }}>Fecha</label>
                            </div>
                            <div className="col-3  sinpadding" onClick={() => this.ordenarLista('titulo')}>
                                <label className="txttitlestable cursor">Nombre</label>
                            </div>
                            <div className="col-3  sinpadding" onClick={() => this.ordenarLista('namesubcat')}>
                                <label className="txttitlestable cursor">Categoría</label>
                            </div>
                            <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('tipo')}>
                                <label className="txttitlestable cursor">Tipo</label>
                            </div>
                            <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('pagado')}>
                                <label className="txttitlestable cursor">Pagado</label>
                            </div>


                        </div>
                        <div className='divscrollhome' style={{ maxHeight: "600px", minHeight: "100px" }} >

                            {this.state.txt_buscador.length == 0 ?
                                <>{sortedData.length > 0 ? <>
                                    {sortedData.map((item, index) => (

                                        <div className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                            onClick={this.seleccionarTabla.bind(this, item.idcuentas, item.idpaquete)}>

                                            <div className="col-2 spaciostabla ">
                                                <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                            </div>

                                            <div className="col-3 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">
                                                    <b style={{ minwidth: "50px" }}>{"(" + item.total + ")"}</b> - {item.titulo != ""
                                                        ? item.titulo : "-----"}</label>
                                            </div>

                                            <div className="col-3 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                            </div>

                                            <div className="col-2 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">{item.tipo}</label>
                                            </div>

                                            <div className="col-1 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">
                                                    {item.pagado == "0" ?
                                                        <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                        : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                </label>
                                            </div>

                                        </div>
                                    ))}
                                </> :
                                    <div className="row" >
                                        <div className="col-12">
                                            <br />
                                            <p style={{ fontSize: "16px", color: "black", width: "100%", textAlign: "center" }}><b > Sin registros </b></p>
                                        </div>
                                    </div>

                                }

                                </>
                                :
                                <>
                                    {this.state.array_filtro.map((item, index) => (


                                        <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                        onClick={this.seleccionarTabla.bind(this, item.idcuentas, item.idpaquete)}>

                                            <div className="col-2 spaciostabla ">
                                                <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                            </div>

                                            <div className="col-3 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">{item.titulo != "" ? item.titulo : "-----"}</label>
                                            </div>

                                            <div className="col-3 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                            </div>

                                            <div className="col-2 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">{item.tipo}</label>
                                            </div>

                                            <div className="col-1 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">
                                                    {item.pagado == "0" ?
                                                        <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                        : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                </label>
                                            </div>

                                        </div>


                                    ))}
                                </>}



                        </div>
                        <br />
                        <div className="modalmessage-footer">
                            <button className="buttonbase message-alert-ok" onClick={() => this.closemodal(0)}>Cerrar</button>
                            {this.state.datosUser.email != "" ?
                                <button className="buttonshadow message-alert-cancel" style={{ width: "190px" }}
                                    onClick={this.modalAviso.bind(this, 2)}>Restablecer contraseña</button>
                                : null}

                        </div>

                    </ModalBody>

                </Modal>
            </>
        )

    }
}