import React from "react";
import { Modal, ModalBody, Input } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { FaUserTie } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import "./AgregarUsuarios.css"
import { AiOutlineClose } from "react-icons/ai";
import { agregaUser, cambiarUser } from "../../../servicios/api";

export default class AgregarUsuarios extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            loanding: false,
            show: this.props.show,
            datosUser: props.datosUser,
            bandera: props.bandera,
            modalinfo: props.infoModal,
            idestatus: -1,
            txtuser: "",
            txtcorreo: "",
            txtapellido: "",
            correoPlaceholder: "Correo"

        }
    }

    async componentDidMount() {


        if (this.props.bandera == "editar") {

            var correo = this.state.modalinfo.email[0] + '...' + this.state.modalinfo.email.substring(this.state.modalinfo.email.indexOf('@') - 1)

            this.setState({
                txtuser: this.state.modalinfo.nombre,
                txtapellido: this.state.modalinfo.apellido,
                txtcorreo: '',
                correoPlaceholder: correo
            })

        }

    }

    closemodal = (dato) => {
        this.props.cerrarmodal(dato);
        this.setState({ show: false });
    }


    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    seleccionarestatus = (idestatus) => {
        this.setState({ idestatus: idestatus })
    }

    seleccionarestatus = (idestatus) => {
        this.setState({ idestatus: idestatus })
    }






    guardatos = async () => {


        if (this.state.txtuser.trim().length > 0) {

            if (this.validateEmail(this.state.txtcorreo)) {


                await this.setState({ loanding: true })

                if (this.props.bandera == "nuevo") {
                    let json = {
                        "nombre": this.state.txtuser.trim(),
                        "apellido": this.state.txtapellido.trim(),
                        "email": this.state.txtcorreo.trim()
                    }

                  //console.log(JSON.stringify(json))


                    var resp = await agregaUser(json)
                    if (resp.code == 0) {
                        this.closemodal(1)
                    }
                    else {
                        this.setState({ loanding: false })
                        toast.error(resp.message, { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                        return;
                    }
                }
                else {
                    var datos = localStorage.getItem("datos");
                    let array = datos.split(",");

                    let json = {
                        "token": array[0],
                        "idusuario": this.state.modalinfo.idusuario,
                        "nombre": this.state.txtuser.trim(),
                        "apellido": this.state.txtapellido.trim(),
                        "email": this.state.txtcorreo.trim(),
                        "idestatus": "1"
                    }
                  //console.log(JSON.stringify(json))


                    var resp = await cambiarUser(json)
                    if (resp.code == 0) {
                        this.closemodal(1)
                    }
                    else {
                        this.setState({ loanding: false })
                        toast.error(resp.message, { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                        return;
                    }

                }

            } else {
                toast.warn("Correo inválido", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                return;
            }


        } else {
            toast.warn("Escribe un nombre", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
            return;
        }




    }


    validateEmail = (email) => {
        // Expresión regular para validar el correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }





    handlePasswordChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }


    render() {
        return (

            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    style={{ width: "350px" }}
                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >



                        <div className="divperfilediv">


                            <div className="row" >
                                <div className="col-auto ">
                                    <label className="lbltitlesModal" style={{ fontSize: "large" }}>{this.props.bandera == "editar" ? "Actualizar usuario" : "Agregar usuario"}</label>
                                </div>
                                <div className="col">
                                    <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }} onClick={this.closemodal.bind(this, 0)} /></label>
                                </div>
                            </div>


                            <div className="user-profile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <br />

                                <div className="profile-details" style={{ textAlign: 'center', marginTop: "10px", width: "94%" }}>
                                    <div style={{ position: "relative", width: "100%" }}>

                                        <FaUserTie style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Nombre" type={"text"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtuser")} value={this.state.txtuser} />


                                    </div>

                                    <br />
                                    <div style={{ position: "relative", width: "100%" }}>

                                        <FaUserTie style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder="Apellido (Opcional)" type={"text"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtapellido")} value={this.state.txtapellido} />

                                    </div>
                                    <br />

                                    <div style={{ position: "relative", width: "100%" }}>

                                        <MdAlternateEmail style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                        <Input className="inputprofile" placeholder={this.state.correoPlaceholder} type={"email"} style={{ paddingLeft: "35px" }}
                                            onChange={this.handlePasswordChange("txtcorreo")} value={this.state.txtcorreo} />


                                    </div>






                                    <Button
                                        onClick={this.guardatos}
                                        variant="contained"
                                        fullWidth
                                        style={{
                                            marginTop: "25px",
                                            textTransform: "capitalize",
                                            borderRadius: "30px",
                                            padding: "3px",
                                            fontSize: "medium",
                                            backgroundColor: "#4137a4",

                                            color: "white"
                                        }}

                                    >
                                        {this.props.bandera == "editar" ? "Actualizar" : "Agregar"}
                                    </Button>

                                </div>
                            </div>



                        </div>

                    </ModalBody>


                </Modal>
            </>
        )
    }
}

