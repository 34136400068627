import axios from "axios";
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { DelateLogin, LogInCall } from "./StoreCall";
var CryptoJS = require("crypto-js");




var api = "https://www.koin.izttra.com";
//var api = "http://localhost";
var apigeneral = "/koin-api/web/";
var x_accesskey = "1081592e5bcf4d20a3233e044a0171f4";
var SecretKey = "116971e670ad4dc6821dd3ea75d7f671";



export async function consumir_api(apis, json, bandera) {

   var dia_ac = new Date(),
      month = '' + (dia_ac.getMonth() + 1),
      day = '' + dia_ac.getDate(),
      year = dia_ac.getFullYear(),

      h = '' + dia_ac.getHours(),
      m = '' + dia_ac.getMinutes(),
      ss = '' + dia_ac.getSeconds();

   if (month.length < 2) {
      month = '0' + month;
   }
   if (day.length < 2) {
      day = '0' + day;
   }

   if (h.length < 2) {
      h = '0' + h;
   }
   if (m.length < 2) {
      m = '0' + m;
   }
   if (ss.length < 2) {
      ss = '0' + ss;
   }

   let dia_actual = [year, month, day].join('-');
   let hora_actual = [h, m, ss].join(':');
   var fecha = dia_actual + ' ' + hora_actual;
   //console.log(fecha + x_accesskey + apis);

   var token = Base64.stringify(hmacSHA512(fecha + x_accesskey + apis, SecretKey));
   //console.log("Bearer " + token);


   try {

      var headerstype = 'application/json';
      if (bandera == 'formdata') {
         headerstype = 'multipart/form-data'
      }

      var consulta = await axios.post(api + apis, json, {
         headers: {
            "content-type": headerstype,
            "x-accessKey": x_accesskey,
            "x-date": fecha,
            "x-signature": "Bearer " + token,
         },
      });
      var resp = consulta.data;
      return resp;
   } catch (error) {
      return -1;
   }




}


function mensajeerrorconexion() {
   return "Error de conexión, revisa tu conexión a internet"
}


///////////////////////////////////  Login   //////////////////////////////////////////////////////////////

export async function validarlogin(email, pass) {

   var json = { "email": email, "password": pass }
   var resp = [];
   try {

      var datos = await consumir_api(apigeneral + "usuarios/apikey", json)
      if (datos.code == 1) {
         resp.push({ code: 1, data: [], message: "Error" })
      }
      else {
         var temporal = datos.data;

         console.log(JSON.stringify(datos.data))

         if (temporal.length > 0) {
            resp.push({
               code: 0,
               data: ''
            })
            LogInCall(temporal[0].token + "," + temporal[0].nombre + "," + temporal[0].apellido + "," + temporal[0].email);
         }
         else {
            DelateLogin();
            resp.push({
               code: 1, data: [],
               message: "Usuario o contraseña incorrectos"
            })
         }
      }

   } catch (error) {
      console.log("sin conexion");
      resp.push({
         code: 10,
         message: mensajeerrorconexion()
      })
   }
   return resp[0];
}


///////////////////////////////////  recupera contraseña   //////////////////////////////////////////////////////////////

export async function recuperarPass(email) {

   var json = { "email": email }
   var resp = [];
   try {

      var datos = await consumir_api(apigeneral + "usuarios/recuperacion", json)
      if (datos.code == 1) {
         resp.push({ code: 1, data: [], message: "Error" })
      }
      else {
         var temporal = datos.data;

         console.log(JSON.stringify(datos.data))

         if (temporal.length > 0) {
            resp.push({
               code: 0,
               data: ''
            })
            //  LogInCall(temporal[0].apikey + "," + temporal[0].nombre + "," + user + "," + temporal[0].idtipousuario);
         }
         else {
            DelateLogin();
            resp.push({
               code: 1, data: [],
               message: "Usuario o contraseña incorrectos"
            })
         }
      }

   } catch (error) {
      console.log("sin conexion");
      resp.push({
         code: 10,
         message: mensajeerrorconexion()
      })
   }
   return resp[0];
}


///////////////////////////////////  recupera contraseña   //////////////////////////////////////////////////////////////
export async function agregaUser(json) {

   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0], "nombre": json.nombre, "apellido": json.apellido, "email": json.email }
   var resp = [];


   try {
      var datos = await consumir_api(apigeneral + "usuarios/nuevo", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}
///////////////////////////////////  actualizar user   //////////////////////////////////////////////////////////////

export async function cambiarUser(json) {

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "usuarios/actualizar-otro", json);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}

///////////////////////////////////  recupera contraseña   //////////////////////////////////////////////////////////////
export async function actualizarUser(json) {

   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0], "nombre": json.nombre, "apellido": json.apellido, "email": json.email }
   var resp = [];


   try {
      var datos = await consumir_api(apigeneral + "usuarios/actualizar", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}

///////////////////////////////////  recupera contraseña   //////////////////////////////////////////////////////////////


export async function getUser() {
   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0] }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "usuarios/lista", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}
export async function addpruebas(json) {


   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "clientes/nuevo-invitado", json);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}

export async function srtPassword(json) {
   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0], "email": json.email }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "usuarios/recuperacion", json);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}

export async function changePassword(json) {
   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0], "password": json.password, "nuevopassword": json.nuevopassword, "nuevopassword": json.nuevopassword }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "usuarios/cambiar-password", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}

/////////////////////////////////////////////////////////////////////////
export async function getCategorias() {

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "categorias/lista", []);
      resp = datos;

   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }

   return resp;
}

/////////////////////////////////////////////////////////////////////////
export async function eliminarUsuario(id, email) {


   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var json = {
      "token": array[0],
      "email": email,
      "idusuario":id
   }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "usuarios/eliminar", json);
      resp = datos;

   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }

   return resp;
}

/////////////////////////////////////////////////////////////////////////
export async function eliminarCliente(idcliente) {


   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var json = {
      "token": array[0],
      "idcliente": idcliente
   }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "catalogo-clientes/eliminar", json);
      resp = datos;

   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }

   return resp;
}

/////////////////////////////////////////////////////////////////////////
export async function eliminarCategoria(id) {

   var json = { "id": id }
   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "categorias/eliminar", json);
      resp = datos;

   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }

   return resp;
}

/////////////////////////////////////////////////////////////////////////
export async function saveCategoria(json) {


   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "categorias/guardar", json);
      resp = datos;

   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }

   return resp;
}

/////////////////////////////////////////////////////////////////////////
export async function getCuentas() {
   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0] }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "catalogo-clientes/catalogo", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}
/////////////////////////////////////////////////////////////////////////
export async function datosDetalles(idcliente) {
   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0], "idcliente": idcliente }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "catalogo-clientes/cuentas", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}
/////////////////////////////////////////////////////////////////////////
export async function restablecePass(email) {
   var datos = localStorage.getItem("datos");
   let array = datos.split(",");

   var mandar = { "token": array[0], "email": email }

   var resp = [];
   try {
      var datos = await consumir_api(apigeneral + "catalogo-clientes/recuperacion", mandar);
      resp = datos;
   } catch (error) {
      //console.log("error de conexion");
      resp.push({
         code: 1000,
         message: "Error de conexión, revisa tu conexión a internet"
      })
   }
   return resp;
}