import React from "react";
import { GrFormSearch } from 'react-icons/gr';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import './MainHome.css';
import { Button } from "@mui/material";
import { RiUserAddFill } from "react-icons/ri";
import AgregarUsuarios from "../../componentes/Modals/AgregarUsuarios/AgregarUsuarios";
import { eliminarUsuario, getUser, srtPassword } from "../../servicios/api";
import { HiOutlineKey } from "react-icons/hi";
import { ToastContainer, toast } from 'react-toastify';
import ModalMessage from "../../componentes/Modals/ModalMessages/ModalMessages";
import { CiEdit } from "react-icons/ci";
import { FaTrashAlt } from "react-icons/fa";

export default class MainHome extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            txtusuario: "",
            txt_buscador: "",
            array: [
                {
                    "usuario": "Arias",
                    "estatus": false,
                    "cuentas": 10,
                    "activo": true,
                },
            ],
            sortBy: "email",
            sortOrder: 'asc',
            verMJS: false,
            datosUser: [],
            array_filtro: [],
            respaldo_data: [],
            banderaModal: "nuevo",
            loanding: false,
            fechaselect: null,
            fechajson: '',
            ver_multipagos: false,
            ver_fecha: false,
            veruser: false,
            fecha: '',
            modalTitulo: "",
            modalMessage: ""
        }

    }

    async componentDidMount() {

        this.setState({ loanding: true });
        var dia_ac = new Date(),
            month = '' + (dia_ac.getMonth() + 1),
            day = '' + dia_ac.getDate(),
            year = dia_ac.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }


        this.setState({ fechajson: [year, month, day].join('-') });
        this.setState({ fecha: [day, month, year].join('/') });

        var resp = await getUser()
        if (resp.code == 0) {
            this.setState({ array: resp.data })
        }
        setTimeout(() => {
            this.ordenarLista('nombre')
            this.setState({ loanding: false });
        }, 500);

    }

    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }


    save_input_locacion = async (e) => {

        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.nombre.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.apellido.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()))

            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }

    eliminar = async (dato, item) => {
        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {
            this.setState({ verMJS: !this.state.verMJS })
            this.setState({ loanding: true });
            var resp = await eliminarUsuario(this.state.respaldo_data.idusuario, this.state.respaldo_data.email)

            if (resp.code == 0) {
                this.componentDidMount()
            }
        }
        else {
            var correo = item.email[0] + '...' + item.email.substring(item.email.indexOf('@') - 1)
            this.setState({
                banderaMensaje: "eliminar",
                respaldo_data: item,
                modalTitulo: "Eliminar Usuario",
                modalMessage: "*" + item.nombre + "* " + correo,
                verMJS: !this.state.verMJS
            })
        }
    }


    cerrarmodal = (dato, item) => {
        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {
            this.setState({ verMJS: !this.state.verMJS })
            this.cambiarPassword(this.state.respaldo_data)
        }
        else {
            this.setState({
                banderaMensaje: "cambiarContrasena",
                respaldo_data: item,
                modalTitulo: "Recuperar contraseña",
                modalMessage: "*" + item.email + "*",
                verMJS: !this.state.verMJS
            })

        }

    }

    cambiarPassword = async (item) => {

        this.setState({ loanding: true });
        let json = {
            "email": item.email
        }
        var resp = await srtPassword(json)
        if (resp.code == 0) {
            toast.success("Se envió un Correo para restablecer la contraseña", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
        }
        setTimeout(async () => {
            this.setState({ loanding: false });
        }, 300);

    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    agregarUser = async (index) => {
        //console.log("Click " + index)
        this.setState({ banderaModal: "nuevo" })
        this.setState({ veruser: true })

    }

    closemodal = (dato) => {
        this.setState({ veruser: !this.state.veruser })
        if (dato == 1) {
            this.componentDidMount();
        }
    }

    editarUser = (item) => {

        this.setState({
            banderaModal: "editar",
            datosUser: item,
            veruser: true
        })

    }


    render() {

        const { array, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return (
            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.veruser == true) ? (
                    <AgregarUsuarios show={this.state.veruser} infoModal={this.state.datosUser} bandera={this.state.banderaModal}
                        cerrarmodal={this.closemodal} />
                ) : null}

                {(this.state.verMJS == true) ? (
                    <ModalMessage show={this.state.verMJS} titulo={this.state.modalTitulo} bandera={this.banderaMensaje}
                        mensaje={this.state.modalMessage} cerrarmodal={this.eliminar} />
                ) : null}

                <div className="contenedorhome  ">

                    <div className="row scroll">
                        <div className="col">

                            <br />
                            <br />
                            <label className="lbllistat letra">Usuarios</label>

                            <br />   <br />

                            <br />
                            <div className="row align-items-end">
                                <div className="col-auto ">
                                    <div className="input-group ">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                        </div>
                                        <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                            className="form-control" aria-label="Username" placeholder="Buscar" aria-describedby="basic-addon1"></input>
                                    </div>
                                </div>

                                <div className="col"></div>
                                <div className="col-auto">
                                    <Button className="bluebutton" onClick={this.agregarUser}><RiUserAddFill style={{ marginRight: "5px" }} />Agregar Usuario</Button>
                                </div>

                            </div>

                            <div className="shr "></div>
                            <br />
                            <br />

                            <div className="divhorizontal ">
                                <div className='divscrollhome'>

                                    {this.state.txt_buscador.length == 0 ?
                                        <>
                                            <div className="row srows">

                                                <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('nombre')}>
                                                    <label className="txttitlestable cursor" >Nombre</label>
                                                </div>
                                                <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('apellido')}>
                                                    <label className="txttitlestable cursor" >Apellido</label>
                                                </div>
                                                <div className="col-3 textocentro sinpadding" onClick={() => this.ordenarLista('email')}>
                                                    <label className="txttitlestable cursor" >Correo</label>
                                                </div>
                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="txttitlestable cursor">Estatus</label>
                                                </div>
                                                <div className="col textocentro sinpadding">

                                                </div>

                                            </div>


                                            {sortedData.map((item, index) => (


                                                <div key={index} className={index % 2 === 0 ? "row eve" : "row low"} >


                                                    <div className="col-2 spaciostabla textocentro sinpadding ">
                                                        <label className="subtexttable">{item.nombre}</label>
                                                    </div>
                                                    <div className="col-2 spaciostabla textocentro sinpadding">
                                                        <label className="subtexttable">{item.apellido}</label>
                                                    </div>
                                                    <div className="col-3 spaciostabla textocentro sinpadding">
                                                        <label className="subtexttable">{item.email && item.email.length > 0 ?
                                                            item.email[0] + '...' + item.email.substring(item.email.indexOf('@') - 1) :
                                                            item.email}</label>
                                                    </div>
                                                    <div className="col-1 spaciostabla textocentro sinpadding">
                                                        <label className="subtexttable">{item.estatus}</label>

                                                    </div>

                                                    <div className="col spaciostabla textocentro sinpadding">

                                                        <FaTrashAlt className="icodelate" onClick={this.eliminar.bind(this, 3, item)} />

                                                        <Button className="buttonCats" style={{ width: "fit-content", fontSize: "12px", marginRight: "3px", borderRadius: "5px", paddingLeft: "2px", paddingRight: "6px" }}
                                                            onClick={this.editarUser.bind(this, item)}><CiEdit style={{ marginRight: "5px" }} />Editar</Button>
                                                        <Button className="greenbutton" style={{ fontSize: "12px" }} onClick={this.cerrarmodal.bind(this, 2, item)}>
                                                            <HiOutlineKey style={{ marginRight: "5px" }} />Rst Contraseña</Button>


                                                    </div>

                                                </div>

                                            ))}

                                        </> :
                                        <>

                                            <div className="row srows">

                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="txttitlestable">Nombre</label>
                                                </div>
                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="txttitlestable">Apellido</label>
                                                </div>
                                                <div className="col-3 textocentro sinpadding">
                                                    <label className="txttitlestable">Correo</label>
                                                </div>
                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="txttitlestable">Estatus</label>
                                                </div>
                                                <div className="col textocentro sinpadding">

                                                </div>

                                            </div>


                                            {this.state.array_filtro.map((item, index) => (
                                                <div key={index} className={index % 2 === 0 ? "row eve" : "row low"} >


                                                    <div className="col-2 spaciostabla textocentro sinpadding ">
                                                        <label className="subtexttable">{item.nombre}</label>
                                                    </div>
                                                    <div className="col-2 spaciostabla textocentro sinpadding">
                                                        <label className="subtexttable">{item.apellido}</label>
                                                    </div>
                                                    <div className="col-3 spaciostabla textocentro sinpadding">
                                                        <label className="subtexttable">
                                                            {item.email && item.email.length > 0 ?
                                                                item.email[0] + '...' + item.email.substring(item.email.indexOf('@') - 1) :
                                                                item.email}
                                                        </label>
                                                    </div>
                                                    <div className="col-1 spaciostabla textocentro sinpadding">
                                                        <label className="subtexttable">{item.estatus}</label>

                                                    </div>

                                                    <div className="col spaciostabla textocentro sinpadding">
                                                        <FaTrashAlt className="icodelate" onClick={this.eliminar.bind(this, 3, item)} />
                                                        <Button className="buttonCats" style={{ width: "fit-content", fontSize: "12px", marginRight: "3px", borderRadius: "5px", paddingLeft: "2px", paddingRight: "6px" }}
                                                            onClick={this.editarUser.bind(this, item)}><CiEdit style={{ marginRight: "5px" }} />Editar</Button>
                                                        <Button className="greenbutton" style={{ fontSize: "12px" }} onClick={this.cerrarmodal.bind(this, 2, item)}><HiOutlineKey style={{ marginRight: "3px" }} />Rst Contraseña</Button>
                                                    </div>

                                                </div>

                                            ))}

                                        </>}

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </>
        )
    }
}