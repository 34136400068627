import React from "react";
import { Modal, ModalBody, Input } from "reactstrap";
import { Button } from "@mui/material";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { AiOutlineClose } from "react-icons/ai";
import "./ModalDetalles.css";
import { GiCheckMark } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';


export default class ModalSubDetalles extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            loanding: false,
            show: props.show,
            datosUser: props.datosUser,
            bandera: props.bandera,
            modalinfo: props.infoModal,
            array_filtro: [],
            array: [],
            idestatus: -1,
            txtuser: "",
            txtcorreo: "",
            txtapellido: "",
            txt_buscador: "",
            correoPlaceholder: "Correo"

        }
    }

    async componentDidMount() {

        console.log(this.props.array)
        this.setState({ array: JSON.parse(this.props.array) })
    }


    closemodal = (response) => {
        this.setState({ show: false });
        this.props.cerrarmodal(response, this.state.bandera);
    }

    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }


    formatearFecha = (fecha) => {
        const date = new Date(fecha);
        const dia = date.getDate();
        const mes = date.toLocaleString('es-ES', { month: 'short' });
        const year = date.getFullYear();
        return `${dia}/${mes}/${year}`;
    }


    seleccionarTabla = (idcuentas) => {
        const { array } = this.state;
        const newData = [];
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (item.idcuentas === idcuentas) {
                newData.push({ ...item, seleccionar: !item.seleccionar });
            } else {
                newData.push({ ...item, seleccionar: false });
            }
        }
        this.setState({ array: newData });
    }

    render() {

        const { array, sortBy, sortOrder } = this.state;
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });


        if (!this.props.show) return null;

        return (

            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered

                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >



                        <div className="divperfilediv">


                            <div className="row" >
                                <div className="col-auto ">
                                    <label className="lbltitlesModal" style={{ fontSize: "large" }}>Cuentas: {sortedData.length} {sortedData.length > 0 ? " - " + sortedData[0].titulo : "-----"} </label>
                                </div>
                                <div className="col">
                                    <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }} onClick={this.closemodal.bind(this, 0)} /></label>
                                </div>
                            </div>

                            <div className="row" style={{ backgroundColor: "#51a3ed", color: "black", paddingRight: "15px" }}>


                                <div className="col-2  sinpadding" onClick={() => this.ordenarLista('fechainicio')}>
                                    <label className="txttitlestable cursor" style={{ paddingLeft: "5px" }}>Fecha</label>
                                </div>
                                <div className="col-3  sinpadding" onClick={() => this.ordenarLista('titulo')}>
                                    <label className="txttitlestable cursor">Nombre</label>
                                </div>
                                <div className="col-3  sinpadding" onClick={() => this.ordenarLista('namesubcat')}>
                                    <label className="txttitlestable cursor">Categoría</label>
                                </div>
                                <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('tipo')}>
                                    <label className="txttitlestable cursor">Tipo</label>
                                </div>
                                <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('pagado')}>
                                    <label className="txttitlestable cursor">Pagado</label>
                                </div>


                            </div>
                            <div className='divscrollhome' style={{ maxHeight: "300px", minHeight: "100px" }} >

                                {this.state.txt_buscador.length == 0 ?
                                    <>
                                        {sortedData.map((item, index) => (

                                            <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                onClick={this.seleccionarTabla.bind(this, item.idcuentas)}>

                                                <div className="col-2 spaciostabla ">
                                                    <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                                </div>

                                                <div className="col-3 spaciostabla sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.titulo != "" ? item.titulo : "-----"}</label>
                                                </div>

                                                <div className="col-3 spaciostabla sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                                </div>

                                                <div className="col-2 spaciostabla textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.tipo}</label>
                                                </div>

                                                <div className="col-1 spaciostabla textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">
                                                        {item.pagado == "0" ?
                                                            <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                            : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                    </label>
                                                </div>

                                            </div>
                                        ))
                                        }


                                    </>
                                    :
                                    <>
                                        {this.state.array_filtro.map((item, index) => (


                                            <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                onClick={this.seleccionarTabla.bind(this, item.idcuentas)}>

                                                <div className="col-2  ">
                                                    <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                                </div>

                                                <div className="col-3  sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.titulo != "" ? item.titulo : "-----"}</label>
                                                </div>

                                                <div className="col-3  sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                                </div>

                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.tipo}</label>
                                                </div>

                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">
                                                        {item.pagado == "0" ?
                                                            <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                            : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                    </label>
                                                </div>

                                            </div>


                                        ))}
                                    </>}



                            </div>



                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}
